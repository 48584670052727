<template>
    <div>
        <div class="pb-6 diagonal pb-8 pt-0 pt-md-5" >
            <div class="row">
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col"><h3 class="mb-0">Jazyky</h3></div>
                                <div class="col text-right">
                                    <router-link to="/language/add"  class="nav-link"
                                                 href="/language/add">
                                        <template>
                                            <a-button type="primary" >Nový jazyk</a-button>
                                        </template>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :rowKey="record => record.id"
                                    :dataSource="data"
                                    :loading="loading"
                                    class="table-flush">
                                <template slot="name" slot-scope="permission">
                                    <base-dropdown class="dropdown" position="right">
                                        <a slot="title" class="btn btn-sm btn-icon-only text-light" permission="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <template>
                                            <sidebar-item :link="{name: 'Upraviť', path: '/language/' + permission.id}"/>
                                            <a-popconfirm
                                                    title="Are you sure delete this permission?"
                                                    @confirm="confirm(permission.id)"
                                                    @cancel="cancel"
                                                    okText="Yes"
                                                    cancelText="No">
                                                <a href="#">Zmazať</a>
                                            </a-popconfirm>
                                        </template>
                                    </base-dropdown>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"

    const columns = [
        {
            title: 'Typ obsahu',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'short',
            dataIndex: 'short',
        },
        {
            title: 'Primary',
            dataIndex: 'def',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    export default {
        name: "languages",
        components: {
            "a-popconfirm": Popconfirm
        },
        data() {
            return {
                data: [],
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                columns,
            };
        },
        mounted() {
            this.fetch();
        },
        methods: {
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            cancel() {
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name).then(results => {
                    this.data = results;
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
